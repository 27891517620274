
const WPAPI = require("wpapi");

export default function wp() {
    const wpauth = new WPAPI({
        endpoint: process.env.endpoint,
        username: process.env.NEXT_PUBLIC_ACCOUNT_ENV_VARIABLE,
        password: process.env.NEXT_PUBLIC_PASSWORD_ENV_VARIABLE,
    });
    return wpauth;
}
